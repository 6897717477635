<template>

<div class="wrapper">
        <div class="container">

            <div class="section__line">
                <div class="link__top">
                    <a  class="link__back" @click="$router.go(-1)">
                        <span class="link__back--img">
                            <img src="../../../assets/img/arrow-back.svg">
                        </span> Назад
                    </a>
                </div>
            </div>

            <div class="section__line">

                <div class="section__bg section--bank__info section--bank__data">

                    <div class="section__title--block section__title--margin">

                        <div class="item__column">
                            <div class="section__title">
                                   Редактирование 
                                   Приложение №2. Заявление на отзыв регистрационного свидетельства
                            </div>
                            
                            <div class="item__row item__ac agreement__lang mt-4">
                                <div class="agreement__lang__type item__abs mr-2 pointer" @click="lang_type=false"  v-bind:class="{agreement__lang__active:lang_type==false}">RU</div>
                                <div class="agreement__lang__type item__abs pointer"   @click="lang_type=true" v-bind:class="{agreement__lang__active:lang_type==true}">KZ</div>
                            </div>
                        </div>


                        <div class="type__contract">
                            <div class="type__contract--img">
                                <img src="../../../assets/img/type__contract.svg">
                            </div>
                            <div class="type__contract--info">
                                <div class="type__contract--type">
                                    Тип договора
                                </div>
                                <div class="type__contract--name" v-if="document.type">
                                    {{document.type.title}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="agreement__info--block">
                        <!-- <div class="agreement__info--item agreement__info--status">
                            <div class="agreement__info--icon">
                                <img src="../assets/img/status-icon.svg">
                            </div>
                            <div class="agreement__info--right">
                                <div class="agreement__info--type">
                                    Статус
                                </div>
                                <div class="agreement__info--title">
                                    Создание
                                </div>
                            </div>
                        </div> -->
                        <div class="agreement__info--item">
                            <div class="agreement__info--icon">
                                <img src="../../../assets/img/file-icon.svg">
                            </div>
                            <!-- <div class="agreement__info--right">
                                <div class="agreement__info--type">
                                    Вид договора
                                </div>
                                <div class="agreement__info--title">
                                    Внешний
                                </div>
                            </div> -->
                        </div>
                    </div>

                

                               <form @submit.prevent="createContract()">

                        <hr class="line__hr">

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type"> 
                                                Наименование юридического лица
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                Наименование юридического лица (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required v-if="!lang_type" v-model="uc_kcmr.client_name_company"   placeholder="Наименование юридического лица" value="">
                                                <input type="text" required v-else    v-model="uc_kcmr.client_name_company_kz"  placeholder="Наименование юридического лица  (на казахском)" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title"  v-if="!lang_type">
                                                Юридический адрес
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                Юридический адрес (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required v-if="!lang_type"  v-model="uc_kcmr.client_address"   placeholder="Юридический адрес" value="">
                                                <input type="text" required v-else    v-model="uc_kcmr.client_address_kz" placeholder="Юридический адрес  (на казахском)" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                БИН / ИИН / VATIN
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required   v-model="uc_kcmr.client_bin" placeholder="БИН / ИИН / VATIN" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                Номер свидетельство о регистрации
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required v-model="uc_kcmr.certificate_number"  placeholder="Номер свидетельство о регистрации" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                Дата выдачи cвидетельство о регистрации
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="date" v-model="uc_kcmr.certificate_date" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type">
                                                
                                                Выдавший орган cвидетельство о регистрации
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                
                                                Выдавший орган cвидетельство о регистрации (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required v-model="uc_kcmr.certificate_given" v-if="!lang_type"   placeholder="Выдавший орган cвидетельство о регистрации" value="">
                                                <input type="text" required  v-model="uc_kcmr.certificate_given_kz"  v-else   placeholder="Выдавший орган cвидетельство о регистрации (на казахском)" value="">

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type">
                                                Действующего на основании
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                Действующего на основании (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required  v-model="uc_kcmr.client_based_on"  v-if="!lang_type" placeholder=" Действующего на основании" >
                                                <input type="text" required  v-model="uc_kcmr.client_based_on_kz" v-else placeholder=" Действующего на основании (на казахском)">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type">
                                                В связи с(причина отзыва (аннулирования))
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                В связи с(причина отзыва (аннулирования))
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <textarea    style="border:1px solid #ccc"  v-model="uc_kcmr.client_cause" rows="7" cols="20" v-if="!lang_type" placeholder="Причина отзыва (аннулирования)"></textarea>
                                                <textarea     style="border:1px solid #ccc"  v-model="uc_kcmr.client_cause_kz" rows="7" cols="20" v-else placeholder="Причина отзыва (аннулирования)"></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                Номер регистрационного свидетельства(серийный номер и дата выдачи регистрационного свидетельства)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <textarea   v-model="uc_kcmr.client_long_cert_data"  style="border:1px solid #ccc" rows="7" cols="20" placeholder="Серийный номер и дата выдачи регистрационного свидетельства"></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                Уполномоченное лицо(должность, фамилия, имя, отчество, оригинал доверенности, ИИН, серия и номер паспорта/удостоверения, кем и когда выдан)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <textarea    style="border:1px solid #ccc" rows="7" cols="20" placeholder="должность, фамилия, имя, отчество, оригинал доверенности, ИИН, серия и номер паспорта/удостоверения, кем и когда выдан"></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type">
                                                Должность Уполномоченное лицо
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                Должность Уполномоченное лицо (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required  v-model="uc_kcmr.client_responsible"  v-if="!lang_type"   placeholder="Должность Уполномоченное лицо" value="">
                                                <input type="text" required  v-model="uc_kcmr.client_responsible_kz" v-else   placeholder="Должность Уполномоченное лицо (на казахском)" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                   ИИН, серия и номер паспорта/удостоверения  уполномоченного лица
                                            </div>
                                         
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <textarea    v-if="!lang_type" style="border:1px solid #ccc" rows="7" cols="20" 
																								v-model="uc_kcmr.client_iin"
																								placeholder="ИИН, серия и номер паспорта/удостоверения  уполномоченного лица"></textarea>
																								<textarea   v-else  style="border:1px solid #ccc" rows="7" cols="20" 
																								v-model="uc_kcmr.client_iin_kz"
																								placeholder="ИИН, серия и номер паспорта/удостоверения  уполномоченного лица"></textarea>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title">
                                                Кем выдан паспорта/удостоверения
                                            </div>
                                         
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="text" required  v-model="uc_kcmr.given_by"  v-if="!lang_type"   placeholder=" Кем выдан паспорта/удостоверения" value="">
                                                <input type="text" required  v-model="uc_kcmr.given_by_kz" v-else   placeholder=" Кем выдан паспорта/удостоверения( на казахском)" value="">
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">

                                            <div class="agreement__item--title">
                                                Когда выдан паспорта/удостоверения
                                            </div>
                                         
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <input type="date" v-model="uc_kcmr.date_given" />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>




                
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3">
                                            <div class="agreement__item--title" v-if="!lang_type">
                                                ФИО Уполномоченное лицо
                                            </div>
                                            <div class="agreement__item--title" v-else>
                                                ФИО Уполномоченное лицо (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input">
                                                <textarea    v-if="!lang_type" style="border:1px solid #ccc" rows="7" cols="20" placeholder="  Должность Уполномоченное лицо"></textarea>
                                                <textarea    v-else style="border:1px solid #ccc" rows="7" cols="20" placeholder="  Должность Уполномоченное лицо"></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line" >
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row align-items-center">
                                            <div class="col-lg-3">
                                                <div class="agreement__item--title">
                                                    Файл
                                                </div>
                                            </div>
                                            <div class="col-lg-9">
                                                
                                                    <input type="file" @change="uploadDocument" multiple>
                                            
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>




                        <hr class="line__hr">

                        <div class="row">

                            <div class="col-lg-9 offset-lg-3">

                                <button type="submit" class="form__button">
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z" fill="white"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z" fill="white"></path>
                                    </svg>
                                    Отправить
                                </button>

                            </div>

                        </div>

                    </form>
                

                      

                </div>

            </div>
        </div>
        <!-- <iframe id="blob-src-test" frameborder="0" width="300px" height="300px"></iframe> -->
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    components: {
     
    },
    data () {
        return {
            lang_type:false,
            document: '',
            uc_kcmr: {
                client_signer_in: '',
                client_signer_in_kz: '',
                client_fio: '',
                client_fio_kz: '',
                client_based_on: 'Устава',
                client_based_on_kz: 'Жарғыдан',
                client_phone_number: '',
                client_bin:'',
                client_bin_iin_vatin: ''
            },
            images: [],
            document_id: ''
        }
    },
    created () {
        this.connection = new WebSocket("wss://127.0.0.1:13579/")
        this.connection.onopen = () => {
            this.ready = true
        }
        this.connection.onmessage = (e) => {
            const arr = JSON.parse(e.data);
            const parse__arr = arr
            this.esp__array = parse__arr
        };
    },
 
    methods: {
        uploadDocument(e) {
            const file = e.target.files
            for (var i=0; i < file.length; i++) {
                this.images.push(file[i])
            }
        },
        getProfile() {
            this.uc_kcmr.client_name_company = this.GET_USER_DATA.organization.name;
            this.uc_kcmr.client_name_company_kz = this.GET_USER_DATA.organization.name;
            this.uc_kcmr.client_bin = this.GET_USER_DATA.organization.bin;
            this.uc_kcmr.client_phone_number = this.GET_USER_DATA.organization.phone;
            this.uc_kcmr.client_fio = this.GET_USER_DATA.common_name+' '+this.GET_USER_DATA.last_name;
            this.uc_kcmr.client_fio_kz =  this.GET_USER_DATA.common_name+' '+this.GET_USER_DATA.last_name;
            this.uc_kcmr.client_signer_in = this.GET_USER_DATA.role.name_ru;
            this.uc_kcmr.client_signer_in_kz = this.GET_USER_DATA.role.name_kz;
            this.uc_kcmr.client_last_name = this.GET_USER_DATA.last_name;
            this.uc_kcmr.client_last_name_kz = this.GET_USER_DATA.last_name;
            this.uc_kcmr.client_bik = this.GET_USER_DATA.organization.bik;
            this.uc_kcmr.client_bin_iin_vatin = this.GET_USER_DATA.organization.bin;
        },

        createContract() {
     
            let contractForm = new FormData();           
            for (var key in this.uc_kcmr) {
                contractForm.append('attachments[attachment_1]['+key+']',this.uc_kcmr[key] )
            }

            for (var i = 0; i < this.images.length; i++) {
                contractForm.append('documents[]', this.images[i])
            }
            contractForm.append('attachment_id',this.$route.params.id)
            contractForm.append('type',"attachment_2");
            
            
            this.$axios.post(this.$API_URL + this.$API_VERSION +   'user/documents/uckcmr/create/second/attachment', contractForm, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token_kcmr')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if(response.data) {
                    this.$toast.open({
                        message: "Успешно заполнено",
                        type: 'success',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
                }
                 this.$router.push('/uckcmr/view/'+this.document_id);
            })   
            .catch((error) => {
                console.log(error.response);

                let errors = error.response.data.errors; console.log(errors)

                    this.$toast.open({
                        message: "Заполните все поля",
                        type: 'error',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
            });
        },
        getTypes() {
            this.$axios({ 
                method: 'get',
                url: this.$API_URL + this.$API_VERSION + 'types', 
                 headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token_kcmr')}` 
                },
            })
            .then((response) => {
                this.document.types = response.data.data;
            })
            .catch((error) => {
                console.warn(error);
            }); 
        },
        getAttachment() {
            this.$axios.get(this.$API_URL + this.$API_VERSION + 'user/attachment?id='+this.$route.params.id, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token_kcmr')}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.uc_kcmr = response.data.fields;
                this.document_id = response.data.document_id;
            })   
            .catch((error) => {
                console.log(error.response);

                let errors = error.response.data.errors; console.log(errors)

                    this.$toast.open({
                        message: "Заполните все поля",
                        type: 'error',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
            });
        }
  
    },
    mounted() {
        this.getAttachment();
        this.getTypes();
        this.getProfile();
    },
    watch: {
      
    },
    computed: {
        ...mapGetters(['GET_TOKEN', 'GET_USER_DATA']),
    },
}
</script>
